@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
* {
    scrollbar-width: thin;
    scrollbar-color: #fe9f00 transparent;
}


/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #fe9f00;
    border-radius: 20px;
    border: 3px solid transparent;
}

.table-row td {
    -webkit-transition: border-bottom-right-radius 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
    transition: border-bottom-right-radius 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
    -webkit-transition: border-bottom-left-radius 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
    transition: border-bottom-right-radius 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
    -webkit-transition: border-color 0.1s cubic-bezier(0.11, 0.24, 0, 0.51);
    transition: border-color 0.1s cubic-bezier(0.11, 0.24, 0, 0.51);
    transition: background-color 0.1s !important;
    -webkit-transition: background-color 0.1s !important;
    transition: color 0.3s linear;
}

.side-color {
    transition: width 0.1s linear;
}

.second-row td {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    -webkit-transition: border-color 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
    transition: border-color 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
}